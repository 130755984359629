import { getCssText, reset as resetStitches } from '@iheartradio/web.companion';
import { RemixBrowser } from '@remix-run/react';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { startTransition, useCallback, useMemo, useState } from 'react';
import { hydrateRoot } from 'react-dom/client';

import ClientStyleContext from './contexts/client-style';

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [sheet, setSheet] = useState(getCssText());

  const reset = useCallback(() => {
    resetStitches();
    const css = getCssText();
    setSheet(css);
  }, []);

  return (
    <ClientStyleContext.Provider
      // memoize the value for this provider to minimize re-renders
      value={useMemo(() => ({ reset, sheet }), [reset, sheet])}
    >
      {children}
    </ClientStyleContext.Provider>
  );
}

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  whyDidYouRender(React);
}

startTransition(() => {
  hydrateRoot(
    document,
    <ClientCacheProvider>
      <RemixBrowser />
    </ClientCacheProvider>,
  );
});
